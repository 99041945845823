import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints.styles";

export const Header = styled.header`
  margin-top: 16rem;
  width: 100%;
  height: auto;
  //height: calc(100vh - 16rem);
  
  .gatsby-image-wrapper {
    height: 100%;
  }
  
  ${breakpoints('bigDesktop') `
    height: auto;
  `} 
  
  ${breakpoints('tabLand') `
    margin-top: 8.2rem;
    // height: calc(100vh - 8.2rem);
  `} 
  ${breakpoints('phone') `
    // height: 20rem;
  `} 
  ${breakpoints('xs') `
    height: 16rem;
  `}
`;

export const ImageLayer = styled.div`
  position: relative;
  z-index: 2;
`;

export const BlackGrid = styled.div`
  width: 100%;
  background-color: #131415;
  padding: 18rem 0 16rem;
  margin-top: -18rem;
  
  ${breakpoints('phone') `
    padding: 6rem 0 16rem;
    margin-top: -6rem;
  `}
`;

export const BlockRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  
  ${breakpoints('tabLand') `
     justify-content: space-evenly;
  `}
  
  ${breakpoints('tabPort') `
     flex-direction: column;
  `}
`;

export const BlockRowImage = styled.div`
  width: 64%;
  
  ${breakpoints('tabPort') `
     width: 100%
  `}
`;

export const BlockRowContent = styled.div`
  width: 32%;
  
  ${breakpoints('tabPort') `
     width: 100%;
     margin-top: 4rem;
  `}
`;

export const BlockTitle = styled.div`
  color: ${props => props.color ? props.color : 'black'};
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-align: ${props => props.align ? props.align : 'left'};
  
  ${breakpoints('tabPort') `
    font-size: 2.2rem;
    text-align: center;
  `} 
`;

export const BlockText = styled.div`
  color: ${props => props.theme.colors.text};
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: ${props => props.align ? props.align : 'left'};
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -12rem;
  margin-bottom: 7rem;
  
  ${breakpoints('phone') `
    flex-direction: column;
    align-items: center;
  `} 
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  
  ${breakpoints('phone') `
     width: 100%;
     margin-bottom: 3rem;
  `} 
`;

export const Bottom = styled.div`
  position: relative;
  
  h2 {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 3.6rem;
    margin-bottom: 3rem;
    text-transform: uppercase;
    
    ${breakpoints('tabPort') `
      font-size: 3.2rem;
    `}
  }
  
  .container {
    position: relative;
    
    .btn-map {
      position: absolute;
      bottom: 5rem;
      right: 0;
      z-index: 2;
      
      ${breakpoints('xs') `
        bottom: -32rem;
      `}
    }
  }
  
  ${breakpoints('phone') `
     position: relative;
     display: flex;
     flex-direction: column;
  `} 
`;



export const BottomMap = styled.div`
  position: absolute;
  top: 0;
  left: 35%;
  width: 60%;
  height: 100%;
  
  img {
    //width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  ${breakpoints('phone') `
    position: relative;
    width: 100%;
    height: 30rem;
    margin-top: 4rem;
    left: 0;
  `}
  
  ${breakpoints('phone') `
     // width: 100%;
  `} 
`;