import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints.styles";

export const ContactTop = styled.div`
  background-color: black;
  height: calc(100vh - 20rem);
  min-height: 45rem;
  max-height: 80rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 16rem;
  
  ${breakpoints('tabLand') `
    height: calc(100vh - 8rem);
    margin-top: 8rem;
  `} 
  
  ${breakpoints('phone') `
    display: flex;
    flex-direction: column;
    height: auto;
  `}
  
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ContactTopTitle = styled.h1`
  color: white;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 3.6rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  
  ${breakpoints('tabPort') `
    font-size: 3.2rem;
  `} 
`;

export const Line = styled.div`
  width: 12rem;
  height: 2px;
  background-color: ${props => props.theme.colors.primary};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '4rem'};
`;

export const ContactAddress = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 3rem;
  justify-content: center;
  
  strong {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  span {
    font-size: 1.8rem;
    font-weight: 200;
    margin-bottom: 5px;
  }
`;

export const ContactMap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  
  ${breakpoints('phone') `
    position: relative;
    width: 100%;
    margin-top: 4rem;
  `}
  
  .btn-map {
    position: absolute;
    bottom: 5rem;
    right: 10%;
    
    ${breakpoints('xs') `
      bottom: 2rem;
    `}
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
`;

export const ContactBottom = styled.div`
  background: url("/images/bg_kontakt.svg") no-repeat top;
  background-size: cover;
  margin-top: -4rem;
  padding-top: 12rem;
  position: relative;
  z-index: 2;
  
  .container {
    display: flex;
    
    ${breakpoints('phone') `
      flex-direction: column;
    `}
  }
  
  h2 {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 3.6rem;
    margin-bottom: 3rem;
    text-transform: uppercase;
    
    ${breakpoints('tabPort') `
      font-size: 3.2rem;
    `}
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 34%;
  margin-right: 10rem;
  margin-bottom: 8rem;
  
  ${breakpoints('tabPort') `
    width: 46%;
    margin-right: 4rem;
  `}
  
  ${breakpoints('phone') `
    width: 100%;
    margin-right: 0;
  `}
  
  h4 {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 2.4rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  
  span {
    display: flex;
    margin-bottom: 1rem;
    
    a {
      display: block;
      margin-left: 5px;
    }
  }
  
  strong {
    margin-bottom: 1rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 45rem;
  margin-bottom: 6rem;
  
  ${breakpoints('phone') `
    width: 100%;
    margin-top: 4rem
  `}
`;

export const FormInfo = styled.div`
  color: ${props => props.theme.colors.text};
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
`;

export const ContactBottomImg = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(30%, -40%);
  z-index: -1;
  
  ${breakpoints('bigDesktop') `
    display: block;
  `} 
  
  img {
    width: 44vw;
  }
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4rem;
`;

export const SocialTitle = styled.div`
  color: black;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.6rem;
  text-transform: uppercase;
  margin-right: 1rem;
`;

export const SocialLink = styled.a`
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1rem;
`;