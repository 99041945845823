import React from 'react'
import { Social, SocialTitle, SocialLink } from "./contact.styles";

const ContactSocial = () => (
  <Social>
    <SocialTitle>znajdź nas na:</SocialTitle>
    <SocialLink href='https://www.facebook.com/Dodge.RAM.Rzeszow' target="_blank" rel="nofollow noreferrer" >
      <img src="/images/icons/icon-fb.svg" alt=""/>
    </SocialLink>
    <SocialLink href='https://www.instagram.com/dodge.ram.rzeszow/' target="_blank" rel="nofollow noreferrer" >
      <img src="/images/icons/icon-instagram.svg" alt=""/>
    </SocialLink>
  </Social>
)

export default ContactSocial