import React from "react";
import Layout from "../components/layout";
import Image from "../components/elements/Image";
import { Header, BlackGrid, BlockRow, BlockRowContent, BlockRowImage, BlockTitle, BlockText, ImageLayer, Row, Card, Bottom, BottomMap } from "../components/salon/salon.styles";
import { Title } from "../styles/typography.styles";
import { Text, CardLine } from "../components/models/models.styles";
import { ContactAddress, ContactInfo, ContactMap, Line } from "../components/contact/contact.styles";
import ContactSocial from "../components/contact/contact-social";
import BtnLink from "../components/elements/BtnLink";

const Salon = () => {
  return (
    <Layout title={'Salon'}>
      <Header>
        <Image fileName={'durango_banner@2x.jpg'}  alt={"Salon Dodge & Ram Rzeszów"}/>
      </Header>

      <main>
        <div className="container">
          <Title>Salon Dodge & Ram Rzeszów</Title>

          <Text maxWidth={"90rem"} style={{marginBottom: '4rem'}}>
            Nasze miejsce pracy mocno związane jest z Amerykańską kulturą. Możesz tutaj znaleźć nie tylko wymarzoną markę samochodową ale również coś mniejszego i równie wyjątkowego. Każdej chwili spędzonej w naszym salonie towarzyszyć <br/>
            Ci będzie niesamowita atmosfera i niemały kawał historii.
          </Text>

          <ImageLayer>
            <Image fileName={'2020-dodge-challenger-interior-carefully-customized.png'} alt={'Salon Dodge & Ram Rzeszów'}/>
          </ImageLayer>
        </div>

        <BlackGrid>
          <div className="container">
            <Title color='white'>pierwszy salon legendarnych marek samochodowych na Podkarpaciu</Title>

            <BlockRow>
              <BlockRowImage>
                <Image fileName={'salon_technology_01@2x.jpg'} alt={'Salon Dodge & Ram Rzeszów'}/>
              </BlockRowImage>
              <BlockRowContent>
                <BlockTitle color='white'>Dodge & Ram</BlockTitle>
                <CardLine/>
                <BlockText color='#e7e7e7'>
                  Marka samochodowa Dodge & Ram nie wzięła się
                  z przypadku w naszej świątyni. Pojazdy te charakteryzują się wysokim współczynnikiem bezpieczeństwa, niezawodności oraz wszechstronności.
                  <br/><br/>
                  Zależy nam by nasi Klienci podróżując czuli, że są w dobrych rękach a produkt, który sprzedajemy dopasuje się do ich wymagań.
                </BlockText>
              </BlockRowContent>
            </BlockRow>
          </div>
        </BlackGrid>

        <Row className={`container`}>
          <Card>
            <Image fileName={'salon_card_1.jpg'} alt={'Salon Dodge & Ram Rzeszów'}/>
          </Card>
          <Card>
            <Image fileName={'salon_card_2.jpg'} alt={'Salon Dodge & Ram Rzeszów'}/>
          </Card>
          <Card>
            <Image fileName={'salon_card_3.jpg'} alt={'Salon Dodge & Ram Rzeszów'}/>
          </Card>
        </Row>

        <Bottom>
          <div className="container">
            <h2>skontaktuj się z nami</h2>

            <ContactInfo>
              <h4>Dodge & Ram Rzeszów</h4>
              <Line marginBottom="2rem"/>

              <strong>adres salonu:</strong>
              <span>ul. Ludwika Chmury 4</span>
              <span>35-213 Rzeszów</span>
              <br/>
              <strong>godziny otwarcia:</strong>
              <span>wtorek-piątek 10:00-18:00</span>
              <br/>

              <h4>Doradca handlowy</h4>
              <Line/>

              <strong>Miłosz Opaliński</strong>
              <span>tel.: <a href="tel:+48532081817">+48 532 081 817</a></span>
              <span>e-mail: <a href="mailto:salon@dodge.rzeszow.pl">salon@dodge.rzeszow.pl</a></span>
              <br/>

              <ContactSocial/>
            </ContactInfo>

            <BtnLink className={`btn-map`} url="https://goo.gl/maps/HgRSrNgdnnKUGCddA" label="zobacz na mapie"/>
          </div>

          <BottomMap>
            <img src={'/images/salon_map.png'} alt="Mapa dojazdu - Salon Dodge & Ram Rzeszów"/>
          </BottomMap>
        </Bottom>
      </main>

    </Layout>
  )
}

export default Salon